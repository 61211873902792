.xpat-home {
  font-family: 'Arial', sans-serif;
  color: #333;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.xpat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 10000;
  animation: fadeIn 0.5s ease-out;
}

.xpat-logo {
  font-size: 2rem;
  font-weight: bold;
  color: #ffd700;
  height: 65px;
  transition: transform 0.3s ease;
}

.xpat-logo:hover {
  transform: scale(1.05);
}

.xpat-nav {
  display: flex;
  gap: 25px;
}

.xpat-nav a {
  text-decoration: none;
  color: #333333;
  font-weight: bold;
  padding: 8px 10px;
  transition: all 0.3s ease;
  position: relative;
}

.xpat-nav a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #007bff;
  transition: width 0.3s ease;
}

.xpat-nav a:hover {
  color: #007bff;
}

.xpat-nav a:hover::after {
  width: 100%;
}

.xpat-menu-icon {
  display: none;
  cursor: pointer;
}

.xpat-appointment-btn {
  background-color: #e68a00;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(230, 138, 0, 0.3);
}

.xpat-appointment-btn:hover {
  background-color: #ff9900;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(230, 138, 0, 0.5);
}

@media (max-width: 768px) {
  .xpat-nav {
    display: none;
  }
  .xpat-header{
    height: 4rem;
  }

  .xpat-menu-icon {
    display: block;
  }

  .xpat-header {
    flex-wrap: wrap;
  }

  .xpat-appointment-btn {
    margin-top: 10px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  /* Hide the nav links by default on small screens */
  .xpat-nav {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background-color: #fff;
    display: none;
    flex-direction: column;
    text-align: center;
    gap: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 0;
  }
  .xpat-nav.active {
    display: flex;
  }
  

  /* Show the hamburger menu icon */
  .xpat-menu-icon {
    display: block;
    color: black;
  }

  /* Hide the appointment button on small screens */
  .xpat-appointment-btn {
    display: none;
  }
  .xpat-company-content{
    flex-direction: column;
  }
  .xpat-services-grid{
    flex-direction: column;
  }
  .xpat-contact{
    flex-direction: column;
  }
  .xpat-features {
    flex-direction: column;
  }
  .xpat-services{
    padding: 0rem 2rem !important;
  }
  .xpat-hero h1{
    font-size: 2.5rem !important;
  }
  .xpat-features-list{
    display: flex !important;
    flex-direction: column;
  }
}

.video-hero {
  position: relative;
  height: 80vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  padding: 0 2rem;
}

.video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  object-fit: cover;
  z-index: 1;
}

.video-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Dark overlay to improve text contrast */
  z-index: 2;
}

.hero-content {
  position: relative;
  z-index: 3; /* Ensure content is above the overlay */
  max-width: 800px;
}

.hero-content h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7);
}

.hero-content p {
  font-size: 1.5rem;
  max-width: 700px;
  margin: 0 auto 2rem;
}

.detail-btn {
  background-color: #e68a00;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2rem;
  color: #fff;
  transition: background-color 0.3s, transform 0.3s;
}

.detail-btn:hover {
  background-color: #ff9900;
  transform: translateY(-3px);
}

.detail-btn:active {
  background-color: #cc7a00;
  transform: translateY(0);
}

@media (max-width: 767px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }
  
  .hero-content p {
    font-size: 1.2rem;
  }
}

.xpat-company {
  padding: 4rem 2rem;
  background-color: #f5f5f5;
}

.xpat-company h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.xpat-company-content {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.xpat-company-text {
  flex: 1;
}

.xpat-company-image {
  flex: 1;
  background-image: url('./images/ronnie-george-9gGvNWBeOq4-unsplash.jpg');
  background-size: cover;
  background-position: center;
  height: 400px;
  border-radius: 10px;
}

:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --text-color: #2c3e50;
  --bg-color: #ecf0f1;
  --card-bg: #ffffff;
  --hover-color: #e74c3c;
}

.xpat-services {
  padding: 8rem 2rem;
  position: relative;
  overflow: hidden;
}

.xpat-services::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  animation: pulse 15s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.xpat-services h2 {
  font-size: 3.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  display: flex;
  justify-content: centergit;
}

.xpat-services h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background: var(--primary-color);
}

.xpat-services > p {
  max-width: 800px;
  margin: 0 auto 5em;
  font-size: 1.2rem;
  color: var(--text-color);
  line-height: 1.8;
  opacity: 0.8;
}

.xpat-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 3rem;
  max-width: 1600px;
  margin: 0 auto;
}

.xpat-service-card {
  background: var(--card-bg);
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;
  z-index: 1;
}

.xpat-service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: -1;
}

.xpat-service-card:hover {
  transform: translateY(-15px) scale(1.03);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.2);
}

.xpat-service-card:hover::before {
  opacity: 1;
}

.xpat-card-content {
  padding: 3rem 2.5rem;
  position: relative;
  z-index: 2;
  transition: all 0.5s ease;
}

.xpat-service-card:hover .xpat-card-content {
  transform: translateY(-10px);
}

.xpat-card-icon {
  font-size: 4rem;
  margin-bottom: 2rem;
  color: var(--primary-color);
  transition: all 0.5s ease;
}

.xpat-service-card:hover .xpat-card-icon {
  transform: scale(1.2) rotate(10deg);
  color: var(--card-bg);
}

.xpat-service-card h3 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: var(--text-color);
  transition: color 0.5s ease;
}

.xpat-service-card:hover h3 {
  color: var(--card-bg);
}

.xpat-service-card p {
  color: var(--text-color);
  margin-bottom: 2rem;
  line-height: 1.7;
  transition: color 0.5s ease;
}

.xpat-service-card:hover p {
  color: rgba(255, 255, 255, 0.9);
}

.xpat-read-more {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: var(--primary-color);
  color: var(--card-bg);
  text-decoration: none;
  font-weight: bold;
  border-radius: 50px;
  transition: all 0.5s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

.xpat-read-more::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.5s ease;
}

.xpat-read-more:hover::before {
  left: 100%;
}

.xpat-service-card:hover .xpat-read-more {
  background-color: var(--card-bg);
  color: var(--primary-color);
}

.xpat-hover-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  object-fit: cover;
  transform: scale(1.1);
}

.xpat-service-card:hover .xpat-hover-image {
  opacity: 0.2;
  transform: scale(1);
}

@media (max-width: 768px) {
  .xpat-services h2 {
    font-size: 2.5rem;
  }
  
  .xpat-services-grid {
    grid-template-columns: 1fr;
  }
}

.xpat-features {
  display: flex;
  align-items: stretch;
  background-color: #f7f7f7;
  padding: 2rem;
}

.xpat-features-content {
  flex: 1;
  padding: 4rem 2rem;
  background-color: #333;
  color: #fff;
}

.xpat-features-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #ffd700; /* Gold for title to match construction theme */
  text-transform: uppercase; /* Makes the title look bolder */
  letter-spacing: 1px;
}

.xpat-features-content p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  line-height: 1.6;
  color: #e0e0e0; /* Slightly lighter color for contrast */
}

.xpat-features-list {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.xpat-features-list li {
  position: relative;
  padding-left: 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: #fff;
}

.xpat-features-list li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #ffd700;
  font-weight: bold;
  font-size: 1.2rem;
}

.xpat-contact-btn {
  display: inline-block;
  font-size: 1.2rem;
  padding: 0.75rem 2rem;
  margin-top: 2rem;
  color: #fff;
  background-color: #ff9900; /* Construction-themed orange */
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Adds a shadow for depth */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.xpat-contact-btn:hover {
  background-color: #e68a00; /* Slightly darker on hover */
  transform: translateY(-3px); /* Subtle lift effect */
}

.xpat-features-image {
  flex: 1;
  background-image: url('./images/rename.jpg');
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Soft shadow */
}


.xpat-projects {
  padding: 2rem;
  text-align: center;
}

.xpat-projects h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
}

.xpat-slider {
  position: relative;
}

.xpat-project-slide {
  padding: 0 1rem;
  
}

.xpat-project-slide img {
  width: 100%;
  height: auto;
  max-height: 300px; /* Reduced the max-height to make images smaller */
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.slick-prev, .slick-next {
  width: 60px; /* Increased arrow size */
  height: 60px;
  line-height: 60px;
  font-size: 30px; /* Increased font size for arrows */
  color: #fff;
  background: rgba(0, 0, 0, 0.7); /* Darker background for better visibility */
  border-radius: 50%;
  text-align: center;
  transition: background 0.3s;
  z-index: 1;
}

.slick-prev:hover, .slick-next:hover {
  background: rgba(0, 0, 0, 0.9); /* Darker background on hover */
}

.slick-prev {
  left: 15px; /* Moved arrows a little closer */
}

.slick-next {
  right: 15px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .xpat-project-slide img {
    max-height: 1000px; /* Further reduced image height for tablets */
  }

  .slick-prev, .slick-next {
    width: 50px;
    height: 50px;
    font-size: 24px; /* Reduced arrow size for smaller screens */
  }
}

@media (max-width: 480px) {
  .xpat-project-slide img {
    max-height: 200px; /* Reduced image height for mobile screens */
    max-width: 200px;
  }

  .slick-prev, .slick-next {
    width: 40px;
    height: 40px;
    font-size: 20px; /* Adjusted arrow size for mobile */
  }
  .slick-slide img{
    display: flex;
    height: 170px;
    width: 79px;
  }
  
}



.xpat-contact {
  display: flex;
  flex-wrap: wrap;
  background-color: #f4f4f4;
  padding: 4rem 2rem; /* Increased padding for a more spacious layout */
  gap: 2rem;
}

.xpat-contact-content {
  flex: 1;
  max-width: 600px;
  background: #fff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.xpat-contact-content::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
  border-radius: 12px;
  z-index: -1; /* Position the gradient behind the content */
}

.xpat-contact-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #333;
  font-weight: 600;
}

.xpat-contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem; /* Increased gap for better spacing */
}

.xpat-contact-form input,
.xpat-contact-form textarea {
  padding: 1.2rem; /* Increased padding for a more comfortable input area */
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.xpat-contact-form input:focus,
.xpat-contact-form textarea:focus {
  border-color: #ff5722; /* Highlight border on focus */
  box-shadow: 0 0 8px rgba(255, 87, 34, 0.2); /* Subtle shadow on focus */
  outline: none; /* Remove default outline */
}

.xpat-submit-btn {
  background-color: #ff5722;
  color: #fff;
  border: none;
  padding: 1rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s;
}

.xpat-submit-btn:hover {
  background-color: #e64a19;
  transform: translateY(-2px); /* Subtle lift effect */
}

.xpat-contact-info {
  flex: 1;
  background-color: #333;
  color: #fff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.xpat-contact-info h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: white;
}

.xpat-contact-info p {
  margin-bottom: 1rem; /* Increased margin for better spacing */
}

.xpat-social-icons {
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem; /* Increased margin for better spacing */
}

.xpat-social-icons svg {
  width: 30px; /* Larger icons for better visibility */
  height: 30px;
  color: #fff;
  transition: color 0.3s, transform 0.3s;
}

.xpat-social-icons svg:hover {
  color: #ff5722;
  transform: scale(1.1); /* Slight zoom effect on hover */
}


.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #ffd700;
}

.slick-prev:before,
.slick-next:before {
  color: #ffd700;
  size: 20px;
}