/* Project1.css */

.project-container {
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
    padding: 2rem;
    margin-top: 5rem; /* Adjust the top margin if needed */
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.banner-container {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
}

.banner-image {
    width: auto;
    height: 5rem;
    object-fit: contain;
}

.details-container {
    text-align: center;
    margin-bottom: 2.5rem;
}

.company-name {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1e3a8a;
    margin-bottom: 0.75rem;
}

.project-name {
    font-size: 1.875rem;
    font-weight: 600;
    color: #1d4ed8;
}

.section {
    margin-bottom: 2.5rem;
}

.section-title {
    font-size: 1.875rem;
    font-weight: 600;
    color: #1e3a8a;
    margin-bottom: 1rem;
}

.highlight-item, .dimensions-list, .amenities-list, .locations-list {
    font-size: 1.125rem;
    color: #4b5563;
    margin-bottom: 0.5rem;
}

.dimensions-list li, .amenities-list li, .locations-list li {
    list-style-type: disc;
    list-style-position: inside;
}

.map-section {
    margin-bottom: 2.5rem;
    text-align: center;
}

.registration-payment-section {
    font-size: 1.125rem;
    color: #4b5563;
}

.contact-section {
    text-align: center;
    margin-bottom: 1.5rem;
}

.contact-title {
    font-size: 1.875rem;
    font-weight: 600;
    color: #1e3a8a;
    margin-bottom: 0.75rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .project-container {
        padding: 1rem;
        margin-top: 3rem;
    }

    .company-name {
        font-size: 2rem;
    }

    .project-name {
        font-size: 1.5rem;
    }

    .section-title {
        font-size: 1.5rem;
    }

    .highlight-item, .dimensions-list, .amenities-list, .locations-list {
        font-size: 1rem;
    }
}
