/* Main About Section */
.plot-about-container {
  margin-top: 3rem;
}

/* Image Section for SUFI */
.sufi-img {
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.sufi-img img {
  width: 100%;
  height: 100%;
}

@media (max-width:730px) {
  
  .sufi-img {
    width: 100%;
    height: 316px;
    overflow: hidden;
}

}

/* About Us Container */
.about-us-container {
  display: flex;
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

.about-us-text {
  flex: 2;
}

.about-us-text h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
}

.about-us-text p {
  font-size: 1em;
  line-height: 1.6;
  color: #666;
  margin-bottom: 15px;
}

/* Logo Section */
.about-us-logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.about-us-logo img {
  max-width: 500px;
  height: auto;
  margin-top: 20rem;
  border-radius: 5px;
  transform: translateX(100px) rotate(0deg);
  opacity: 0;
  animation: spinAndSlide 3s ease-in-out infinite;
  animation-delay: 5s;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Animation for Logo */
@keyframes spinAndSlide {
  0% {
    transform: translateX(100px) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: translateX(0) rotate(180deg);
    opacity: 1;
  }
  100% {
    transform: translateX(-100px) rotate(360deg);
    opacity: 0;
  }
}

/* Hover Effect for Logo */
.about-us-logo img:hover {
  transform: scale(1.1);
  opacity: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-us-container {
    flex-direction: column;
    padding: 20px;
  }
  .about-us-logo {
    order: -1; /* Move logo above text on small screens */
    margin-bottom: 20px;
  }
}

/* Goals and Mission Section */
.goals-mission {
  /* Remove background gradient to make the background transparent */
  background-image: url('../Assets/burj.jpg');
  background-size: cover;
  background-position: center;
  min-height: 600px;
  display: flex;
  align-items: center;
  position: relative;
}

.goals-mission::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Removed the blue background gradient */
  /* background: linear-gradient(to right, rgba(44, 62, 80, 0.9) 50%, rgba(44, 62, 80, 0.6) 100%); */
}

.icon {
  font-size: 2em;
  color: #4cc9f0; /* Changed icon color to a light blue */
}

.goals-mission h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #41cdf7; /* Changed heading color to dark blue */
}

.goalbox h3 {
  font-size: 1.2em;
  margin: 2px;
  color: #2b6d96; /* Changed sub-heading color to a medium purple */
}

.aalu {
  margin: 10px 0 0;
  line-height: 1.6;
  color: #000; /* Changed text color to black */
}

.step-icon svg {
  font-size: 40px;
  color: #4cc9f0; /* Changed icon color to light blue */
}

.step-number {
  background-color: white;
  color: #3a0ca3; /* Changed step number color to medium purple */
}

.founder-section-title {
  font-size: 32px;
  font-weight: bold;
  color: #3a0ca3; /* Changed founder section title color to medium purple */
}

.text-content h2 {
  font-size: 2rem;
  color: #280ea9; /* Changed text heading color to dark blue */
  margin-bottom: 15px;
}

.goalbox .icon {
  font-size: 2em;
  color: #eff4f7;
}


.content-wrapper {
  position: relative;
  z-index: 1;
  /* color: transparent; */
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
}

h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
}

.goalboxs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.goalbox {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.icon {
  font-size: 2em;
  color: #22799b;
}
.goals-mission h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
  color:#58b4ed;
}
.goalbox h3 {
  font-size: 1.2em;
  margin: 2px;
  color: #40a9ef;
}
.goalbox p{
  font-family:'fantasy';
}

.aalu {
  margin: 10px 0 0;
  line-height: 1.6;
  color: #fff;
}

@media (max-width: 768px) {
  .goals-mission {
    min-height: auto;
  }
  .content-wrapper {
    padding: 20px;
  }
  .goalboxs-wrapper {
    gap: 20px;
  }
}

/* Founders Section */
.founders-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.founder-section-title {
  font-size: 32px;
  font-weight: bold;
  color: #174a7d;
  margin-bottom: 10px;
}

.section-subtitle {
  font-size: 18px;
  color: #666;
  margin-bottom: 40px;
}

.founders-grid {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.founder-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
  transition: transform 0.3s ease;
}

.founder-card:hover {
  transform: translateY(-10px);
}

.founder-image {
  width: 100%;
  height: auto;
  border-radius: 5%;
  margin-bottom: 20px;
}

.founder-name {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.founder-title {
  font-size: 16px;
  color: #666;
}

@media (max-width: 768px) {
  .founders-grid {
    flex-direction: column;
  }
}

/* General Section Layout */
.about-section {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px;
  background-color: #f8f9fa;
}

.about-us, .mission, .ours-story {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.text-content {
  flex: 1;
  padding: 20px;
}

.text-content h2 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 15px;
}

.text-content p {
  font-size: 1.1rem;
  color: #6c757d;
  line-height: 1.6;
}

.image-content {
  flex: 1;
}

.image-content img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .about-us, .mission, .ours-story {
    flex-direction: column;
    text-align: center;
  }

  .image-content {
    margin-top: 20px;
  }
}

/* Color Adjustments */

/* How It Works Section */
.how-it-works {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

.how-it-works h2 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 10px;
}

.underline {
  width: 80px;
  height: 3px;
  background-color: #220f65;
  margin: 0 auto 20px;
}

.subtitle {
  color: #666;
  margin-bottom: 40px;
}

.steps-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.step {
  flex-basis: calc(33.333% - 30px);
  max-width: 300px;
}

.step-icon {
  width: 100px;
  height: 100px;
  background-color: #170f69;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
  position: relative;
}

.step-icon svg {
  font-size: 40px;
  color: white;
}

.step-number {
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  color: #1e106e;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.step h3 {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 10px;
}

.step p {
  color: #666;
  font-size: 0.9em;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .step {
    flex-basis: calc(50% - 30px);
  }
}

@media (max-width: 480px) {
  .step {
    flex-basis: 100%;
  }
}

.founder-section-title {
  font-size: 32px;
  font-weight: bold;
  color: #3a0ca3;
  margin-bottom: 10px;
}

.text-content h2 {
  font-size: 2rem;
  color: #280ea9;
  margin-bottom: 15px;
}

.step-icon svg {
  font-size: 40px;
  color: #f4f8f9;
}

.step-number {
  background-color: white;
  color: #301c58;
}