/* Navbar Styling */
.custom-navbar {
  background-color: #fff;
  color: #333;
  padding: 0.5rem 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.custom-navbar:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.custom-nav-logo {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.custom-nav-logo img {
  max-height: 60px;
  display: block;
  transition: transform 0.3s ease;
}

.custom-nav-logo img:hover {
  transform: scale(1.05);
}

.custom-nav-links {
  flex: 1;
  display: flex;
  justify-content: center;
}

.custom-nav-links ul {
  list-style: none;
  display: flex;
  gap: 4rem;
  padding: 0;
  margin: 0;
}

.custom-nav-links li {
  margin: 0;
  display: flex;
  align-items: center;
}

.custom-nav-links a {
  color: #0f0f7a;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.custom-nav-links a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #ff4c4c;
  transition: width 0.3s ease;
}

.custom-nav-links a:hover::after {
  width: 100%;
}

.custom-nav-links a:hover {
  color: #ff4c4c;
  transform: translateY(-2px);
}

.custom-nav-links a svg {
  width: 1.2em;
  height: 1.2em;
  transition: transform 0.3s ease;
}

.custom-nav-links a:hover svg {
  transform: scale(1.1);
}

/* Contact Info and CTA Button Box */
.custom-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.custom-contact-info {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #310ae3;
}

.custom-contact-info a {
  margin-left: 0.5rem;
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.custom-contact-info a:hover {
  color: #ff4c4c;
  transform: translateY(-2px);
}

.custom-cta-navbar-button {
  padding: 0.6rem 1.2rem;
  background-color: #1b1483;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-cta-navbar-button:hover {
  background-color: #e03a3a;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(224, 58, 58, 0.3);
}

/* Mobile Menu Icon */
.custom-mobile-menu-icon {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  height: 24px;
  width: 30px;
  transition: all 0.3s ease;
}

.custom-bar {
  display: block;
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: 0.3s;
}

/* Media Queries */
@media (max-width: 768px) {
  .custom-navbar {
    padding: 0.5rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    height: 65px;
  }

  .custom-nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    background-color: #fff;
    width: 100%;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.3s ease;
  }

  .custom-nav-links.active {
    display: flex;
    opacity: 1;
    transform: translateY(0);
  }

  .custom-mobile-menu-icon {
    display: flex;
    position: absolute;
    right: 1rem;
    top: 20px;
    z-index: 1001;
  }

  .custom-mobile-menu-icon:hover {
    transform: scale(1.1);
  }

  .custom-mobile-menu-icon.active .custom-bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .custom-mobile-menu-icon.active .custom-bar:nth-child(2) {
    opacity: 0;
  }

  .custom-mobile-menu-icon.active .custom-bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .custom-nav-links ul {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 1rem 0;
  }

  .custom-nav-links li {
    justify-content: center;
    width: 100%;
  }

  .custom-nav-links a {
    width: 100%;
    text-align: center;
    padding: 0.5rem 0;
  }

  .custom-box {
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
    width: 100%;
  }

  .custom-cta-navbar-button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 480px) {
  .custom-nav-links ul {
    gap: 0.5rem;
    padding: 24px;
  }

  .custom-nav-links a {
    font-size: 0.9rem;
  }

  .custom-cta-navbar-button {
    font-size: 0.9rem;
  }
}