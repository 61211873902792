.popular-areas-section {
  width: 90%;
  margin-top: 6rem;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.section-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.section-title {
  font-size: 2rem;
  color: rgb(16, 13, 78);
  text-align: center;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 6px;
  width: 100%;
}

.project-box {
  background-size: cover;
  background-position: center;
  height: 400px;
  border-radius: 12px;
  box-shadow: 0 10px 10px 0 rgba(28, 3, 90, 0.5); 
  cursor: pointer;
  transition: transform 0.3s ease;
  margin: 10px;
}

.project-box:hover {
  transform: scale(1.05);
}

.section-bottom {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 6px;
  justify-items: center;
}

.stats-box {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.stats-number {
  font-size: 3rem;
  font-weight: 700;
  color: black;
}

.stats-text {
  font-size: 1.25rem;
  color: gray;
}

.project-cards-container {
  width: 100%;
  padding: 4rem 0;
  background-color: #f9fafb;
  position: relative;
}

.project-cards-content {
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 1rem;
  margin-top: -5.5rem;
}

.project-cards-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 4rem;
  color: #ffa500;
  letter-spacing: 0.1em;
  text-shadow: 2px 2px 4px rgba(255, 165, 0, 0.2);
}

.project-cards-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 768px) {
  .project-cards-grid {
      grid-template-columns: repeat(2, 1fr);
  }
}

.project-card {
  position: relative;
  height: 420px;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  background-size: cover;
  background-position: center;
  transition: box-shadow 0.3s ease;
}

.project-card:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
}

.project-card-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.5) 30%,
      rgba(0, 0, 0, 0.3) 60%,
      transparent 100%
  );
  backdrop-filter: brightness(0.9);
}

.project-card-arrow-container {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  display: flex;
  align-items: center;
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.3));
}

.project-card-arrow-circle {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: pulseGlow 2s infinite;
  border: 1px solid rgba(255, 165, 0, 0.5);
}

.project-card-arrow-icon {
  color: #ffa500;
  animation: floatBounce 2s infinite;
  filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.5));
}

.project-card-sliding-arrows {
  display: flex;
  margin-left: 1rem;
  gap: 0.5rem;
  perspective: 1000px;
}

.project-card-sliding-arrow {
  color: #ffa500;
  transform-style: preserve-3d;
}

.project-card-sliding-arrow:nth-child(1) {
  animation: slideRightFade 1.5s infinite;
}

.project-card-sliding-arrow:nth-child(2) {
  animation: slideRightFade 1.5s infinite 0.2s;
}

.project-card-sliding-arrow:nth-child(3) {
  animation: slideRightFade 1.5s infinite 0.4s;
}

.project-card-number {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  transform: rotate(0deg);
  animation: spinIn 0.6s ease-out;
}

.project-card-number-circle {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 165, 0, 0.5);
  animation: rotatePulse 8s linear infinite;
}

.project-card-number-text {
  color: #ffa500;
  font-weight: bold;
  font-size: 1.25rem;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  animation: counterRotate 8s linear infinite;
}

@keyframes slideRightFade {
  0% {
      transform: translateX(0) translateZ(0);
      opacity: 0.7;
  }
  50% {
      transform: translateX(15px) translateZ(20px);
      opacity: 1;
  }
  100% {
      transform: translateX(0) translateZ(0);
      opacity: 0.7;
  }
}

@keyframes pulseGlow {
  0%, 100% {
      transform: scale(1);
      box-shadow: 0 0 10px rgba(255, 165, 0, 0.3);
  }
  50% {
      transform: scale(1.05);
      box-shadow: 0 0 20px rgba(255, 165, 0, 0.5);
  }
}

@keyframes floatBounce {
  0%, 100% {
      transform: translateY(0) scale(1);
  }
  50% {
      transform: translateY(-5px) scale(1.1);
  }
}

@keyframes spinIn {
  from {
      transform: rotate(-180deg) scale(0);
      opacity: 0;
  }
  to {
      transform: rotate(0) scale(1);
      opacity: 1;
  }
}

@keyframes rotatePulse {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

@keyframes counterRotate {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(-360deg);
  }
}

/* Add glass morphism effect to cards */
.project-card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.05) 100%
  );
  z-index: 1;
  pointer-events: none;
}
