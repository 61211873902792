@tailwind base;
@tailwind components;
@tailwind utilities;


*{
    margin:0;
    padding:0;
}

body{
    background-color: rgb(243,243,243);
}