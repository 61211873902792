/* General Styles */
.construction-page,
.packages {
  font-family: Arial, sans-serif;
  color: #333;
}

/* Header Styles */
.navbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  z-index: 3;
  background-color: #f5f5f5;
  position: sticky;
  top: 0px;
  z-index: 100000;
  height: 5rem;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
}
.cta-navbar-button {
  background-color: #1a237e;
  color: white;
  padding: 0.75rem 2rem;
  border: 2px solid white;
  border-radius: 10px;
  /* font-size: 0px; */
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.logo img {
  height: 65px;
  margin-bottom: 0;
}

.nav-links {
  align-items: center;
  display: flex;
  margin: 10px;
  justify-content: center;
}
.nav-links a {
  transition: background-color 0.3s ease;
}
.nav-links ul {
  display: flex;
  list-style-type: none;
}

.nav-links ul li {
  margin-right: 4rem;
}

.nav-links ul li a {
  text-decoration: none;
  color: #2b3990;
  font-weight: bold;
  align-items: center;
}

.hamburger {
  order: 1; /* This pushes the hamburger to the left */
  cursor: pointer;
  font-size: 1.5rem;
  display: none;
}

.logo {
  order: 2; /* This pushes the logo to the right */
}

.nav-links.open {
  display: block;
}

.nav-links a:hover {
  color: #ff6347; /* Change color on hover */
  background-color: rgba(255, 255, 255, 0.2); /* Light background on hover */
  border-radius: 5px; /* Rounded corners for the highlight */
}
.cta-navbar-button:hover {
  background-color: #e5534d; /* Darker shade of the button color on hover */
}

@media (max-width: 767px) {
  .nav-links ul {
    flex-direction: column;
    width: 100%;
  }
  .nav-links ul li {
    margin: 0.5rem 0;
  }

  .hamburger {
    display: block;
  }
  .logo {
    display: flex;
  }
  .logo img {
    display: flex;
    justify-content: space-between;
  }
  .navbar {
    justify-content: space-between;
    z-index: 10;
    width: 100%;
    height: 62px;
  }
  .nav-links {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff; /* Or your preferred background color */
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    display: none;
  }
  .nav-links.open {
    display: flex;
  }
  .nav-links li {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .cta-buttonss {
    margin-top: 1rem;
    width: 100%;
  }
  .navbar-container {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
  }
  .package-grid {
    display: flex;
    flex-direction: column;
  }
  .package {
    width: 100% !important;
  }
}

.cta-button {
  background-color: #2b3990;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

/* Hero Section Styles */
.hero {
  background-image: url("./imagess/slider_1.jpg");
  background-size: cover;
  color: white;
  padding: 4rem 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.hero {
  position: relative;
  height: 100vh;
  background: url('./imagess//slider_1.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
  z-index: 1;
}

.hero-text {
  position: relative;
  z-index: 2;
  margin-top: 7rem;
  text-align: center;
  color: #fff; /* White text for better contrast */
}

.hero h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
  line-height: 1.2;
  color: #ffd700; /* Golden color */
}

.hero h1 span {
  color: #fac106; /* Slightly darker yellow for emphasis */
}

.hero p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  color: #fff; /* White for better readability */
}

@media (max-width: 767px) {
  .hero h1 {
    font-size: 3rem;
  }
  .hero-text {
    margin-top: 5rem;
  }
  .hero {
    height: auto;
  }
}

/* Packages Section Styles */
.packages {
  padding: 4rem 2rem;
  text-align: center;
}

.packages h2 {
  color: #ffd700;
  text-align: center;
}

.packages h3 {
  color: #000080;
  text-align: center;
}

.package-grid {
  display: flex;
  justify-content: space-around; /* Updated to maintain spacing consistency */
  gap: 2rem;
  margin-top: 2rem;
}

.package {
  background-color: #fff; /* Unified background color */
  border-radius: 10px;
  padding: 2rem; /* Harmonized padding */
  width: 45%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.package h4 {
  background-color: #ffa500;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.price {
  font-size: 1.5rem; /* Unified font size */
  font-weight: bold;
  margin: 1rem 0;
}

.price .original-price {
  text-decoration: line-through;
  color: #999;
  margin-right: 10px;
  font-size: 1rem;
}

.package ul {
  list-style-type: none;
  padding: 0;
}

.package li {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
.feature-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expand-icon {
  cursor: pointer;
  color: #000080;
}

.dropdown {
  background-color: #f0f0f0;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}

.dropdown ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
}

.dropdown li {
  border-bottom: none;
  padding: 5px 0;
}

.enquire-button {
  background-color: #000080;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
}

/* Cost Calculator Styles */
.cost-calculator {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.cost-calculator h2 {
  text-align: center;
  color: #f9a825; /* Golden color */
  font-weight: bold;
}

.cost-calculator h3 {
  text-align: center;
  color: #1a237e; /* Dark blue color */
  margin-bottom: 30px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  margin-right: 20px;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.form-group select,
.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box; /* Ensures padding is included in width/height */
  height: 48px; /* Standardize height across all input fields */
}

.form-group input[type="tel"] {
  height: 48px; /* Ensures the phone number input is consistent with other fields */
}

.cost-button {
  width: 100%;
  padding: 15px;
  background-color: #1a237e; /* Dark blue color */
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cost-button:hover {
  background-color: #0d47a1; /* Darker blue on hover */
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-group {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }
}

/* Signature Designs Section */

/* Base styles */
/* Base styles */
.signature-designs {
  text-align: center;
  padding: 3rem 1rem;
  position: relative;
}

/* Typography */
.signature-designs h2 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #2c3e50;
  font-weight: 600;
}

.signature-designs h3 {
  font-size: 1.5rem;
  margin-bottom: 2.5rem;
  color: #34495e;
  font-weight: 400;
}

.slider-container {
  position: relative;
  padding: 0 40px;
}

/* Card styles */
.design-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  margin: 0 15px;
  height: 200px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s ease;
  overflow: hidden;
}

.design-card:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.design-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

.design-card-content {
  padding: 1rem;
}

.design-card h4 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #2c3e50;
  font-weight: 600;
}

.design-card p {
  font-size: 0.9rem;
  color: #7f8c8d;
  line-height: 1.4;
}

/* Stylish Arrow Styles */
.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: #2c3e50;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  font-size: 22px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.slick-prev:before, 
.slick-next:before {
  color: #2c3e50 !important;
  font-size: 32px !important;
  opacity: 1 !important;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  transition: all 0.3s ease;
}

.slick-prev:before {
  content: '\2190' !important; /* Left arrow Unicode */
}

.slick-next:before {
  content: '\2192' !important; /* Right arrow Unicode */
}

.slick-prev:hover:before,
.slick-next:hover:before {
  color: #e74c3c !important;
  transform: scale(1.2);
}

.slick-prev:active:before,
.slick-next:active:before {
  transform: scale(0.9);
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.slick-prev:hover:before,
.slick-next:hover:before {
  animation: pulse 1s infinite;
}

.arrow:hover {
  background-color: #34495e;
  transform: scale(1.1);
}

.arrow.next {
  right: 20px;
}

.arrow.prev {
  left: 20px;
}

/* Arrow icons */
.arrow::before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(45deg);
}

.arrow.prev::before {
  transform: rotate(-135deg);
}

/* Slick carousel customization */
.slick-list {
  margin: 0 -15px;
}

.slick-slide {
  padding: 15px;
}

.slick-dots {
  bottom: -40px;
  display: none !important;
}

.slick-dots li button:before {
  font-size: 10px;
  color: #bdc3c7;
  opacity: 1;
  transition: color 0.3s ease;
}

.slick-dots li.slick-active button:before {
  color: #2c3e50;
}

/* Show Slick arrows */
.slick-prev,
.slick-next {
  display: flex !important; /* Make sure arrows are visible */
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 40px;
  height: 40px;
  background-color: rgba(44, 62, 80, 0.7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slick-arrow:hover {
  background-color: rgba(44, 62, 80, 1);
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

/* Arrow icon color */
.slick-arrow svg {
  color: black !important;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .signature-designs h2 {
    font-size: 2rem;
  }

  .signature-designs h3 {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }

  .design-card {
    height: 180px;
    margin: 10px 10px;
  }

  .design-card img {
    height: 180px;
  }

  .arrow {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }

  .slider-container {
    padding: 0 30px;
  }

  .slick-arrow {
    width: 30px;
    height: 30px;
  }

  .slick-arrow svg {
    width: 20px;
    height: 20px;
  }
  .slick-prev:before, .slick-next:before {
    font-size: 27px !important;
  }
}


/* Footer page */

footer {
  color: black;
  padding: 3rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-contents {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: rgba(0, 0, 0, 0.3);
  height: auto;
  padding: 1.5rem;
  background-color: #120760;
  border-radius: 15px;
}

.footer-left,
.footer-middle,
.footer-right {
  flex: 1;
  margin: 0 1rem;
}

.footer-left img {
  height: 80px;
  margin-bottom: 1rem;
}

.footer-left p {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: white;
}

.social-icons {
  display: flex;
  gap: 1rem;
  margin:15px 0
}

.social-icons a {
  color: white;
  font-size: 1.8rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #ffd700;
}

.footer-middle ul {
  list-style-type: none;
  padding: 0;
}

.footer-middle ul li {
  margin-bottom: 0.7rem;
}

.footer-middle ul li a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.footer-middle ul li a:hover {
  color: #ffd700;
}

.footer-right p {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: white;
}

.newsletter-form {
  display: flex;
  margin-top: 1rem;
}

.newsletter-form input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ffd700;
  border-radius: 5px 0 0 5px;
  background-color: #fff;
  color: #333;
}

.newsletter-form button {
  background-color: #ffd700;
  color: #2b3990;
  border: 1px solid #ffd700;
  padding: 0.75rem 1.25rem;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.newsletter-form button:hover {
  background-color: #e0c300;
  color: #fff;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  border-top: 1px solid white;
  padding-top: 1.5rem;
  font-size: 0.9rem;
  color: white;
  margin-top: 1rem;
  color: black;
}

.footer-links a {
  color: black;
  text-decoration: none;
  margin-left: 1rem;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #ffd700;
}

@media (max-width: 768px) {
  .footer-contents {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
  }
}


/* project navbar link page */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

.project-construct {
  height: 100vh;
  background-image: url("./imagess/building.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  font-family: "Poppins", sans-serif;
  width: 100%; /* Ensure full width */
}

.content-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding in width calculation */
}

h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
  line-height: 1.2;
}

h2 {
  font-size: 3.5rem;
  font-weight: 700;
  color: #ffa500;
  margin-bottom: 1rem;
  line-height: 1.2;
}

p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 400;
}

.cta-button {
  background-color: transparent;
  color: white;
  padding: 0.75rem 2rem;
  border: 2px solid white;
  border-radius: 5px;
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;

}

.cta-button:hover {
  background-color: white;
  color: black;
}

@media (max-width: 768px) {
    h1, h2 {
    font-size: 2rem; /* Slightly smaller for better fit */
  }
  
  p {
    font-size: 1rem;
  }
  
  .cta-button {
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
  }
  
  .project-construct {
    height: auto; /* Allow height to adjust based on content */
    min-height: 100vh; /* Ensure it's at least full viewport height */
  }
  
  .content-overlay {
    width: 100%; /* Full width on small screens */
    height: 100%; /* Full height */
    overflow-y: auto; /* Allow scrolling if content overflows */
  }
}

/* career construction page */
.rightcon-career-main-page {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.rightcon-highlight {
  color: #ffc107;
}

/* JoinUs component */
.rightcon-join-us {
  text-align: center;
  padding: 40px 20px;
}

.rightcon-join-us__title {
  font-size: 42px;
  color: #1a237e;
  margin-bottom: 10px;
}

.rightcon-join-us__subtitle {
  font-size: 42px;
  color: #1a237e;
  margin-bottom: 20px;
}

.rightcon-join-us__description {
  font-size: 18px;
  margin-bottom: 30px;
}

.rightcon-join-us__image-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.rightcon-join-us__image {
  width: 45%;
  max-width: 37rem;
  height: auto;
}

/* CurrentJobOpenings component */
.rightcon-job-openings {
  padding: 40px 20px;
}

.rightcon-job-openings__title {
  font-size: 42px;
  color: #1a237e;
  text-align: center;
  margin-bottom: 30px;
}

.rightcon-job-openings__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.rightcon-job-card {
  background-color: #f5f5f5;
  border: 1px solid black;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rightcon-job-card__title {
  color: #1a237e;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: bold;
  font-family: "Avenir Next", Sans-serif;
  line-height: 1.3rem;
}

.rightcon-job-card__description {
  margin-bottom: 0px;
  font-size: 16px;
  font-family: "Avenir Next", Sans-serif;
  font-weight: 400;
  line-height: 1.2rem;
  flex-grow: 1;
}
.rightcon-job-card__experience {
  font-weight: bold;
  margin-bottom: 15px;
}

.rightcon-job-card__apply-btn {
  background-color: #1a237e;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  align-self: flex-start;
}
/* HowWeBuildOurTeam component */
.rightcon-team-building {
  padding: 40px 20px;
  margin: 70px 0px;
}

.rightcon-team-building__title {
  font-size: 32px;
  font-weight: 700;
  color: #1a237e;
  text-align: center;
  margin-bottom: 30px;
  font-family: "Avenir Next", Sans-serif;
}

.rightcon-team-building__list {
  list-style-type: none;
  padding: 0;
  max-width: 800px;
  margin: 0 auto;
}

.rightcon-team-building__item {
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
}

.rightcon-team-building__item::before {
  content: "•";
  color: #1a237e;
  font-size: 20px;
  position: absolute;
  left: 0;
}

/* NextChapter component */
.rightcon-next-chapter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  background-color: #1a237e;
  color: #fff;
  width: 50%;
}

.flexing {
  max-width: 50%;
}

.rightcon-next-chapter__title {
  color: #fff;
  font-size: 24px;
  margin-bottom: 20px;
}

.rightcon-next-chapter__join-btn {
  background-color: #ffc107;
  color: #1a237e;
  border: none;
  padding: 10px 30px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
}
.rightcon-next-chapter__image {
  max-width: 45%;
  height: auto;
  position: relative;
  background-color: #1a237e;
}
.wraping {
  display: flex;
  height: 20rem;
  margin-bottom: 2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .rightcon-next-chapter {
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  .wraping {
    display: flex;
    flex-direction: column;
  }

  .rightcon-job-openings__list {
    gap: 70px;
  }

  .rightcon-next-chapter__image {
    max-width: 100%;
    margin-top: 20px;
    display: none;
  }
  .rightcon-join-us__title {
    font-size: 32px;
  }
  .rightcon-join-us__subtitle {
    font-size: 32px;
  }
  .rightcon-job-openings__title {
    font-size: 32px;
  }
  .rightcon-next-chapter {
    margin: -29px 0;
  }
  .contact-construct-form-input {
    font-size: 14px !important;
  }
}

/* Contact Construct Page Styling */
.contact-construct-page {
  background-color: #ffffff;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.contact-construct-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.contact-construct-form-section {
  flex: 1;
  margin-bottom: 2rem;
}

.contact-construct-subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fbbf24;
  margin-bottom: 0.5rem;
}

.contact-construct-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1e3a8a;
  margin-bottom: 2rem;
  line-height: 1.2;
  text-align: center;
}

.contact-construct-form {
  display: flex;
  flex-direction: column;
}

.contact-construct-form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.contact-construct-form-input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  font-size: 1rem;
  border: 1px solid black;
}


.contact-construct-form-textarea {
  width: 100%;
  padding: 0rem;
  border-radius: 0.375rem;
  font-size: 1rem;
  height: 8rem;
  resize: vertical;
  margin-bottom: 1rem;
  border: 1px solid black;
}

.contact-construct-form-submit {
  background-color: #1e3a8a;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-construct-form-submit:hover {
  background-color: #1e40af;
}

/* Info Section */
.contact-construct-info-section {
  flex: 1;
}

.contact-construct-info {
  margin-bottom: 2rem;
}

.contact-construct-info-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.contact-construct-info-icon {
  color: #1e3a8a;
  margin-right: 1rem;
  flex-shrink: 0;
}

.contact-construct-info-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.contact-construct-map-container {
  height: 300px;
  border-radius: 0.5rem;
  overflow: hidden;
}

.contact-construct-divider {
  display: none;
}

@media (min-width: 768px) {
  .contact-construct-container {
    flex-direction: row;
    gap: 2rem;
  }

  .contact-construct-title {
    font-size: 2rem;
  }

  .contact-construct-divider {
    display: flex;
    width: 2px;
    height: 53rem;
    background-color: black;
    margin: 0 3rem;
  }

  .contact-construct-form-section,
  .contact-construct-info-section {
    flex: 1;
  }

  .contact-construct-form-section {
    margin-bottom: 0;
  }
  .contact-construct-form-row{
    display: flex;
    flex-direction: column;
  }
  .contact-construct-form-row{
    display: flex;
    flex-direction: row;
    margin-right: 2px;
    margin-left: -8px;
  }
}

@media (max-width: 768px){
.contact-construct-form-row{
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}
.contact-construct-form-textarea{
  margin-left: 10px;
}
.contact-construct-form-submit {
  margin-left: 10px;
}
.contact-construct-form-textarea{
  width: 94%;
}
}

/* about us page */

.about-construct {
  font-family: Arial, sans-serif;
}

.about-section {
  position: relative;
  text-align: center;
}

.house-image {
  width: 100%;
  height: auto;
}

.about-heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6rem;
  color: rgba(255, 255, 255, 0.1);
  z-index: 1;
}

/* Stats Card */
.company-showcase {
  display: flex;
  justify-content: center;
  gap: 7rem;
  padding: 2rem;
  background-color: #f8f9fa;
}

.showcase-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 250px;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.showcase-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.5)
  );
  transform: rotate(45deg);
  transition: all 0.5s ease;
  opacity: 0;
}

.showcase-card:hover::before {
  opacity: 1;
  transform: rotate(45deg) translate(50%, 50%);
}

.showcase-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.showcase-icon {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: #ffa500;
  transition: all 0.3s ease;
}

.showcase-card:hover .showcase-icon {
  transform: scale(1.2) rotate(360deg);
}

.showcase-card h3 {
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  color: #333333;
  position: relative;
  z-index: 1;
}

.showcase-card p {
  font-size: 1rem;
  color: #666666;
  position: relative;
  z-index: 1;
}

@media (max-width: 768px) {
  .company-showcase {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .showcase-card {
    width: 100%;
    max-width: 300px;
    margin-bottom: 1.5rem;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.showcase-card {
  animation: fadeInUp 0.5s ease forwards;
  opacity: 0;
}

.showcase-card:nth-child(1) { animation-delay: 0.1s; }
.showcase-card:nth-child(2) { animation-delay: 0.3s; }
.showcase-card:nth-child(3) { animation-delay: 0.5s; }
/* Our Story */
.our-story {
  padding: 4rem 2rem;
  text-align: center;
  background: #f4f4f4;
  position: relative;
  overflow: hidden;
}

.our-story h2 {
  color: #f09819;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.our-story h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 700;
  color: #333;
}

.our-story p {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
}


/* Timeline Section */
.timeline-section {
  padding: 2rem 2rem;
  border-radius: 12px;
  margin-bottom: 3rem;
}

.timeline-section h2 {
  font-size: 3rem;
  margin-bottom: 3rem;
  text-align: center;
  color: #333;
  position: relative;
}

.timeline-section h2::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background-color: #f09819;
  margin: 1rem auto 0;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 4px;
  background-color: #ff5722;
  transform: translateX(-50%);
}

.timeline-item {
  position: relative;
  margin-bottom: 4rem;
  width: calc(50% - 30px); /* Adjust width to account for icon */
}

.timeline-item:nth-child(even) {
  left: calc(50% + 30px); /* Adjust left position to account for icon */
}

.timeline-content {
  position: relative;
  padding: 2rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.timeline-icon {
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  background-color: #f09819;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 4px #fff, 0 0 0 8px #f09819;
  z-index: 1;
}

.timeline-item:nth-child(odd) .timeline-icon {
  right: -60px; /* Adjust to fully show icon */
}

.timeline-item:nth-child(even) .timeline-icon {
  left: -60px; /* Adjust to fully show icon */
}

.timeline-date {
  font-size: 1.4rem;
  font-weight: bold;
  color: #f09819;
  margin-bottom: 1rem;
}

.timeline-description h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #333;
}

.timeline-description p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

.timeline-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .timeline::before {
      left: 30px;
  }

  .timeline-item {
      width: calc(100% - 60px);
      left: 60px;
  }

  .timeline-item:nth-child(even) {
      left: 60px;
  }

  .timeline-icon {
      left: -60px;
      display: none;
  }

  .timeline-content {
      padding-left: 45px;
  }
}

/* Testimonials Section */
.testimonials-section {
  background-color: #fff;
  padding: 6rem 2rem;
  border-radius: 12px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1);
}

.testimonials-section h2 {
  font-size: 3rem;
  margin-bottom: 3rem;
  text-align: center;
  color: #333;
  position: relative;
}

.testimonials-section h2::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background-color: #ff5722;
  margin: 1rem auto 0;
}

.testimonials-slider {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding: 2rem 0;
  scroll-snap-type: x mandatory;
}

.testimonial {
  flex: 0 0 420px;
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  scroll-snap-align: start;
  position: relative;
  transition: transform 0.3s ease;
}

.testimonial:hover {
  transform: translateY(-10px);
}

.testimonial-quote {
  position: absolute;
  top: -20px;
  left: 20px;
  color: #ff5722;
  font-size: 3rem;
}

.testimonial p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.testimonial-author {
  display: flex;
  align-items: center;
}

.testimonial-author img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: cover;
}

.testimonial-author-info {
  display: flex;
  flex-direction: column;
}

.testimonial-author-name {
  font-weight: bold;
  color: #333;
}

.testimonial-author-title {
  font-size: 0.9rem;
  color: #777;
}

@media (max-width: 768px) {
  .timeline::before {
      left: 30px;
  }

  .timeline-item {
      width: 100%;
      left: 0;
  }

  .timeline-item:nth-child(even) {
      left: 0;
  }

  .timeline-icon {
      left: 0;
      transform: translateX(-50%);
  }

  .timeline-content {
      padding-left: 20px;
  }

  .testimonials-slider {
      flex-direction: column;
      align-items: center;
  }

  .testimonial {
      max-width: 100%;
  }
}


.what-sets-us-apart {
  padding: 50px;
  background-color: #f9f9f9;
}

.what-sets-us-apart h2 {
  color: #f09819;
  font-size: 2rem;
  text-align: center;
}

.what-sets-us-apart h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}

.feature-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 45%;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-icon {
  font-size: 3rem;
  color: #f09819;
  margin-bottom: 10px;
}

.feature-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.feature-item p {
  font-size: 1rem;
  color: #666;
  text-align: center;
}

@media (max-width: 768px) {
  .our-story h1 {
    font-size: 1.5rem;
  }
  .our-story p {
    font-size: 1rem;
  }
  .stat-item h3 {
    font-size: 1rem;
  }
  .what-sets-us-apart h1 {
    font-size: 1.5rem;
  }
  .feature-icon {
    font-size: 2rem;
  }
  .feature-item h3 {
    font-size: 1rem;
  }
  .feature-item {
    width: 84%;
  }
  .play-button {
    font-size: 1rem;
  }
}

/* calculator */

.cost-calculator, .cost-result-page {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cost-calculator h2, .cost-result-page h2 {
  text-align: center;
  color: #333;
  margin-bottom: 1rem;
}

.cost-calculator h3 {
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  flex: 1;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
}

input, select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.cost-button, .back-button {
  display: block;
  width: 100%;
  padding: 1rem;
  background-color:#000080;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cost-button:hover, .back-button:hover {
  background-color: #ff9900;
}

.result-card {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.result-card h3 {
  color: #e68a00;
  margin-bottom: 1rem;
}

.details p {
  margin-bottom: 0.5rem;
}

.back-button {
  text-decoration: none;
  text-align: center;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}

/* CostResultPage.css */

.cost-result-page {
  background-color: #f0f4f8;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 2rem;
}

.result-container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 900px;
  overflow: hidden;
}

.result-header {
  background-color: #2c3e50;
  color: #ffffff;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-title {
  font-size: 2.2rem;
  margin: 0;
}

.company-logo img {
  height: 50px;
  width: auto;
}

.cost-highlight {
  background: linear-gradient(135deg, #3498db, #2980b9);
  color: #ffffff;
  padding: 2rem;
  text-align: center;
}

.cost-label {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.cost-value {
  font-size: 3.5rem;
  font-weight: bold;
  margin: 0;
}

.result-content {
  display: flex;
  padding: 2rem;
}

.project-summary, .client-details {
  flex: 1;
  padding: 0 1rem;
}

h3 {
  color: #2c3e50;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ecf0f1;
  padding-bottom: 0.5rem;
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

.summary-item, .detail-item {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.summary-icon, .detail-icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.summary-label, .detail-label {
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-bottom: 0.25rem;
}

.summary-value, .detail-value {
  font-size: 1.2rem;
  color: #2c3e50;
  font-weight: 500;
}

.next-steps {
  background-color: #ecf0f1;
  padding: 1.5rem 2rem;
  margin-top: 1rem;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
  background-color: #f8f9fa;
}
.back-button, .contact-button {
  padding: 0.75rem 0.5rem;
  border-radius: 50px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  min-width: 150px;
  text-align: center;
}
.back-button {
  background-color: #e74c3c;
  color: #ffffff;
}

.back-button:hover {
  background-color: #c0392b;
}

.contact-button {
  background-color: #2ecc71;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.contact-button:hover {
  background-color: #27ae60;
}

.error-message {
  color: #e74c3c;
  text-align: center;
  font-size: 1.2rem;
  padding: 2rem;
}

@media (max-width: 768px) {
  .result-content {
    flex-direction: column;
  }
  
  .project-summary, .client-details {
    padding: 1rem 0;
  }
  
  .cost-value {
    font-size: 2.5rem;
  }
  
  .action-buttons {
    flex-direction: column;
    align-items: center;
  }

  .back-button, .contact-button {
    width: 80%;
    max-width: 250px;
  }
}

